<template>
  <div class="wameed-dashboard-page-content">
    <page-header
        :title="$t('financial.title')"
    />


    <b-row class="mb-5">
      <b-col cols="12" md="3">
        <financial-card
            class="w-100"
            :number="getTotal.total_sales"
            :title="$t('financial.card.total_sales')"
            icon="f-sales-icon"
        />
      </b-col>

      <b-col cols="12" md="3">
        <financial-card
            class="w-100"
            :number="getTotal.his_profit"
            :title="$t('financial.card.total_profit')"
            icon="f-profit-icon"
            :is_price="true"
        />
      </b-col>

      <b-col cols="12" md="3">
        <financial-card
            class="w-100"
            :number="getTotal.total_clients"
            :title="$t('financial.card.total_clients')"
            icon="f-clients-icon"
            :is_price="false"
        />
      </b-col>


      <b-col cols="12" md="3">
        <financial-card
            class="w-100"
            :number="getTotal.total_orders"
            :title="$t('financial.card.total_orders')"
            icon="f-orders-icon"
            :is_price="false"
        />
      </b-col>
    </b-row>
    <div>
      <filter-header
          :content="filterContent"
          @applyFilter="applyFilter"
          @resetFilter="resetFilter"
          @orderApplicants="orderData"

          :searchPlaceholder="$t('common.search') + ' ...'"
          :btnTitle="$t('common.filter_data')"
          :menuTitle="$t('common.order_by') + ' : '"
          :subTitle="'asdf'"
          :orderByItems=" [
        {
          name: this.$i18n.t('common.newest'),
          id: 'desc',
        },
        {
          name: this.$i18n.t('common.older'),
          id: 'asc',
        }
      ]"
      />
    </div>
    <section
        class="
        wameed-dashboard-page-content_body
        d-flex
        flex-column
        justify-content-between
        overflow-hidden
      ">
      <div class="d-flex flex-wrap flex-1 ">
        <b-col lg="12" md="12" class="px-0">

          <div class="w-table">
            <w-tables
                :noDataTitle="$t('table.no_data_title')"
                :noDataSubtitle="$t('table.no_data_subtitle')"
                v-if="getData"
                :per-page="filterData.per_page"
                :items="getData"
                :fields="tableFields"
                :class="'financial_table'"
                :custimized-items="[
                { name: 'created_at' },
                { name: 'total_price' },
                { name: 'talqah_commission' },
                { name: 'profit' },
                { name: 'action' },

                {name: 'order_price'},
                {name: 'delivery_price'},

                {name: 'discount_type'},
                {name: 'provider_order_discount'},
                {name: 'calc_provider_order_discount'},
                {name: 'talqah_order_discount'},
                {name: 'calc_talqah_order_discount'},
                {name: 'calc_order_discount'},
                {name: 'provider_delivery_discount'},
                {name: 'calc_provider_delivery_discount'},
                {name: 'talqah_delivery_discount'},
                {name: 'calc_talqah_delivery_discount'},
                {name: 'calc_delivery_discount'},

                {name: 'vendor_talqah_commission_percent'},

                {name: 'talqah_order_commission'},

                {name: 'service_fee'},
                {name: 'driver_talqah_commission_percent'},
                {name: 'talqah_delivery_commission'},

                {name: 'provider_profit'},
              ]"
            >

              <template slot="order_price" slot-scope="{data}">
                <check-financial-value
                  :value="data.item.order_price"
                  />
              </template>

              <template slot="delivery_price" slot-scope="{data}">
                <check-financial-value
                  :value="data.item.delivery_price"
                  />
              </template>

              <template slot="provider_order_discount" slot-scope="{data}">
                <check-financial-value
                  :value="data.item.provider_order_discount"
                  />
              </template>
              <template slot="calc_provider_order_discount" slot-scope="{data}">
                <check-financial-value
                  :value="data.item.calc_provider_order_discount"
                  />
              </template>
              <template slot="talqah_order_discount" slot-scope="{data}">
                <check-financial-value
                  :value="data.item.talqah_order_discount"
                  />
              </template>
              <template slot="calc_talqah_order_discount" slot-scope="{data}">
                <check-financial-value
                  :value="data.item.calc_talqah_order_discount"
                  />
              </template>
              <template slot="calc_order_discount" slot-scope="{data}">
                <check-financial-value
                  :value="data.item.calc_order_discount"
                  />
              </template>
              <template slot="provider_delivery_discount" slot-scope="{data}">
                <check-financial-value
                  :value="data.item.provider_delivery_discount"
                  />
              </template>
              <template slot="calc_provider_delivery_discount" slot-scope="{data}">
                <check-financial-value
                  :value="data.item.calc_provider_delivery_discount"
                  />
              </template>
              <template slot="talqah_delivery_discount" slot-scope="{data}">
                <check-financial-value
                  :value="data.item.talqah_delivery_discount"
                  />
              </template>
              <template slot="calc_talqah_delivery_discount" slot-scope="{data}">
                <check-financial-value
                  :value="data.item.calc_talqah_delivery_discount"
                  />
              </template>
              <template slot="calc_delivery_discount" slot-scope="{data}">
                <check-financial-value
                  :value="data.item.calc_delivery_discount"
                  />
              </template>

              <template slot="vendor_talqah_commission_percent" slot-scope="{data}">
                <check-financial-value
                  :value="data.item.vendor_talqah_commission_percent"
                  />
              </template>

              <template slot="talqah_order_commission" slot-scope="{data}">
                <check-financial-value
                  :value="data.item.talqah_order_commission"
                  />
              </template>

              <template slot="service_fee" slot-scope="{data}">
                <check-financial-value
                  :value="data.item.service_fee"
                  />
              </template>
              <template slot="driver_talqah_commission_percent" slot-scope="{data}">
                <check-financial-value
                  :value="data.item.driver_talqah_commission_percent"
                  />
              </template>
              <template slot="talqah_delivery_commission" slot-scope="{data}">
                <check-financial-value
                  :value="data.item.talqah_delivery_commission"
                  />
              </template>

              <template slot="provider_profit" slot-scope="{data}">
                <check-financial-value
                  :value="data.item.provider_profit"
                  />
              </template>




              <template slot="total_price" slot-scope="{ data }">
                {{ formatNumber(data.item.total_price) }}
              </template>

              <template slot="talqah_commission" slot-scope="{ data }">
                {{ formatNumber(data.item.talqah_commission) }}
              </template>

              <template slot="profit" slot-scope="{ data }">
                {{ formatNumber(data.item.profit) }}
              </template>


              <template slot="created_at" slot-scope="{ data }">
                {{ formatDate(data.item.created_at, 'YYYY/MM/DD') }}
              </template>

              <template slot="action" slot-scope="{ data }">
                <b-dropdown

                    variant="background"
                    class="table-option"
                    toggle-class="text-decoration-none rounded-10 px-2 py-2 "
                    menu-class="rounded-8"
                    no-caret
                    dropleft
                >
                  <template v-slot:button-content>
                    <vertical-dots-icon/>
                  </template>


                  <b-dropdown-item
                      :to="goToDetail(data.item.order_id)"
                  >
                    <span class="text-regular-14 text-font-secondary">
                      <eyeon-icon class="mx-2"/>
                      {{ $t('common.show_details') }}
                    </span>
                  </b-dropdown-item>


                </b-dropdown>
              </template>
            </w-tables>
          </div>

        </b-col>

        <b-col lg="12" md="12" class="px-0">
          <wameed-pagination
              v-if="getTotal"
              v-model="filterData.page"
              :page="filterData.page"
              :total-items="getTotal.totalItems"
              :per_page="filterData.per_page"
              :menu-title="$t('common.show_results')"
              @changePage="changePage"
              @changeCurrentPage="changeCurrentPage"
          />
        </b-col>

      </div>

    </section>

  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import PageHeader from '@/components/wameed/WameedPageHeader.vue';
import FilterHeader from '@/components/wameed/WameedFilterHeader.vue';
import FinancialCard from "@/components/financialCard";

import {WameedPagination, WameedTables as WTables} from 'wameed-ui/dist/wameed-ui.esm'
import CheckFinancialValue from "@/views/pages/financial/components/checkFinancialValue.vue";

export default {
  components: {
    CheckFinancialValue,
    FinancialCard,
    WameedPagination,
    PageHeader,
    FilterHeader,
    WTables,
  },
  data() {
    return {
      filterData: {
        status: '',
        page: 1,
        per_page: 20,
        order_by: 'desc',
        search: '',
      },
      filterContent: [
        {
          title: this.$i18n.t('filters.date'),
          key: "Date",
          id: "id",
          name: "title",
          selectedData: [],
          translate: true,
          type: "date",
          startDate: null,
          endDate: null,
        },

      ],

      tableFields: [
        {
          key: "index",
          label: "#",
          sortable: true,
        },


        {key: "order_no", label: this.$i18n.t('financial.table.order_no'), tdClass: 'financial_row_item'},
        {key: "tran_ref", label: this.$i18n.t('financial.table.tran_ref'), tdClass: 'financial_row_item'},

        {key: "order_price", label: this.$i18n.t('financial.table.order_price'), tdClass: 'financial_row_item'},
        {key: "delivery_price", label: this.$i18n.t('financial.table.delivery_price'), tdClass: 'financial_row_item'},


        {key: "discount_type", label: this.$i18n.t('financial.table.discount_type'), tdClass: 'financial_row_item'},
        {
          key: "provider_order_discount",
          label: this.$i18n.t('financial.table.provider_order_discount'),
          tdClass: 'financial_row_item'
        },
        {
          key: "calc_provider_order_discount",
          label: this.$i18n.t('financial.table.calc_provider_order_discount'),
          tdClass: 'financial_row_item'
        },
        {
          key: "talqah_order_discount",
          label: this.$i18n.t('financial.table.talqah_order_discount'),
          tdClass: 'financial_row_item'
        },
        {
          key: "calc_talqah_order_discount",
          label: this.$i18n.t('financial.table.calc_talqah_order_discount'),
          tdClass: 'financial_row_item'
        },
        {
          key: "calc_order_discount",
          label: this.$i18n.t('financial.table.calc_order_discount'),
          tdClass: 'financial_row_item'
        },

        {
          key: "provider_delivery_discount",
          label: this.$i18n.t('financial.table.provider_delivery_discount'),
          tdClass: 'financial_row_item'
        },
        {
          key: "calc_provider_delivery_discount",
          label: this.$i18n.t('financial.table.calc_provider_delivery_discount'),
          tdClass: 'financial_row_item'
        },
        {
          key: "talqah_delivery_discount",
          label: this.$i18n.t('financial.table.talqah_delivery_discount'),
          tdClass: 'financial_row_item'
        },
        {
          key: "calc_talqah_delivery_discount",
          label: this.$i18n.t('financial.table.calc_talqah_delivery_discount'),
          tdClass: 'financial_row_item'
        },
        {
          key: "calc_delivery_discount",
          label: this.$i18n.t('financial.table.calc_delivery_discount'),
          tdClass: 'financial_row_item'
        },

        {
          key: "vendor_talqah_commission_percent",
          label: this.$i18n.t('financial.table.vendor_talqah_commission_percent'),
          tdClass: 'financial_row_item'
        },
        {
          key: "talqah_order_commission",
          label: this.$i18n.t('financial.table.talqah_order_commission'),
          tdClass: 'financial_row_item'
        },

        {key: "service_fee", label: this.$i18n.t('financial.table.service_fee'), tdClass: 'financial_row_item'},

        {
          key: "driver_talqah_commission_percent",
          label: this.$i18n.t('financial.table.driver_talqah_commission_percent'),
          tdClass: 'financial_row_item'
        },
        {
          key: "talqah_delivery_commission",
          label: this.$i18n.t('financial.table.talqah_delivery_commission'),
          tdClass: 'financial_row_item'
        },

        {key: "provider_profit", label: this.$i18n.t('financial.table.provider_profit'), tdClass: 'financial_row_item'},


        {
          key: 'action',
          label: this.$i18n.t('table.options'),
          tdClass: 'w-1/8'
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getData: 'vendor/financial/getData',
      getTotal: 'vendor/financial/getTotal',
    }),
  },

  created() {
    this.loadData();
  },
  methods: {
    ...mapActions({
      loadFinancial: 'vendor/financial/get',
    }),
    changeCurrentPage(item) {
      this.filterData = {
        ...this.filterData,
        per_page: item.id,
      };
      this.loadData();
    },
    changePage(event) {
      this.filterData = {
        ...this.filterData,
        page: event,
      };
      this.loadData();
    },
    loadData() {
      this.loadFinancial(this.filterData);
    },

    goToDetail(id) {
     return {
        name: 'order-detail',
        params: {lang: this.$i18n.locale, id},
      };
    },
    applyFilter() {
      this.filterContent.forEach(item => {
        if (item.key === "Date") {
          if (item.startDate)
            this.filterData['startDate'] = item.startDate.toString();
          if (item.endDate)
            this.filterData['endDate'] = item.endDate.toString();
        } else {
          this.filterData[item.key] = item.selectedData.toString();
        }
      });
      this.loadData();
    },


    resetFilter() {
      this.filterContent.forEach((item, i) => {
        this.filterContent[i].selectedData = [];
        this.filterData[item.key] = item.selectedData.toString();
      });
      this.loadData();
    },
    orderData(item) {
      this.filterData = {
        ...this.filterData,
        ...item,
      };
      this.loadData();
    },
  },
};
</script>
