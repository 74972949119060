var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wameed-dashboard-page-content"},[_c('page-header',{attrs:{"title":_vm.$t('financial.title')}}),_c('b-row',{staticClass:"mb-5"},[_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('financial-card',{staticClass:"w-100",attrs:{"number":_vm.getTotal.total_sales,"title":_vm.$t('financial.card.total_sales'),"icon":"f-sales-icon"}})],1),_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('financial-card',{staticClass:"w-100",attrs:{"number":_vm.getTotal.his_profit,"title":_vm.$t('financial.card.total_profit'),"icon":"f-profit-icon","is_price":true}})],1),_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('financial-card',{staticClass:"w-100",attrs:{"number":_vm.getTotal.total_clients,"title":_vm.$t('financial.card.total_clients'),"icon":"f-clients-icon","is_price":false}})],1),_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('financial-card',{staticClass:"w-100",attrs:{"number":_vm.getTotal.total_orders,"title":_vm.$t('financial.card.total_orders'),"icon":"f-orders-icon","is_price":false}})],1)],1),_c('div',[_c('filter-header',{attrs:{"content":_vm.filterContent,"searchPlaceholder":_vm.$t('common.search') + ' ...',"btnTitle":_vm.$t('common.filter_data'),"menuTitle":_vm.$t('common.order_by') + ' : ',"subTitle":'asdf',"orderByItems":[
      {
        name: this.$i18n.t('common.newest'),
        id: 'desc',
      },
      {
        name: this.$i18n.t('common.older'),
        id: 'asc',
      }
    ]},on:{"applyFilter":_vm.applyFilter,"resetFilter":_vm.resetFilter,"orderApplicants":_vm.orderData}})],1),_c('section',{staticClass:"\n      wameed-dashboard-page-content_body\n      d-flex\n      flex-column\n      justify-content-between\n      overflow-hidden\n    "},[_c('div',{staticClass:"d-flex flex-wrap flex-1 "},[_c('b-col',{staticClass:"px-0",attrs:{"lg":"12","md":"12"}},[_c('div',{staticClass:"w-table"},[(_vm.getData)?_c('w-tables',{class:'financial_table',attrs:{"noDataTitle":_vm.$t('table.no_data_title'),"noDataSubtitle":_vm.$t('table.no_data_subtitle'),"per-page":_vm.filterData.per_page,"items":_vm.getData,"fields":_vm.tableFields,"custimized-items":[
              { name: 'created_at' },
              { name: 'total_price' },
              { name: 'talqah_commission' },
              { name: 'profit' },
              { name: 'action' },

              {name: 'order_price'},
              {name: 'delivery_price'},

              {name: 'discount_type'},
              {name: 'provider_order_discount'},
              {name: 'calc_provider_order_discount'},
              {name: 'talqah_order_discount'},
              {name: 'calc_talqah_order_discount'},
              {name: 'calc_order_discount'},
              {name: 'provider_delivery_discount'},
              {name: 'calc_provider_delivery_discount'},
              {name: 'talqah_delivery_discount'},
              {name: 'calc_talqah_delivery_discount'},
              {name: 'calc_delivery_discount'},

              {name: 'vendor_talqah_commission_percent'},

              {name: 'talqah_order_commission'},

              {name: 'service_fee'},
              {name: 'driver_talqah_commission_percent'},
              {name: 'talqah_delivery_commission'},

              {name: 'provider_profit'} ]},scopedSlots:_vm._u([{key:"order_price",fn:function(ref){
            var data = ref.data;
return [_c('check-financial-value',{attrs:{"value":data.item.order_price}})]}},{key:"delivery_price",fn:function(ref){
            var data = ref.data;
return [_c('check-financial-value',{attrs:{"value":data.item.delivery_price}})]}},{key:"provider_order_discount",fn:function(ref){
            var data = ref.data;
return [_c('check-financial-value',{attrs:{"value":data.item.provider_order_discount}})]}},{key:"calc_provider_order_discount",fn:function(ref){
            var data = ref.data;
return [_c('check-financial-value',{attrs:{"value":data.item.calc_provider_order_discount}})]}},{key:"talqah_order_discount",fn:function(ref){
            var data = ref.data;
return [_c('check-financial-value',{attrs:{"value":data.item.talqah_order_discount}})]}},{key:"calc_talqah_order_discount",fn:function(ref){
            var data = ref.data;
return [_c('check-financial-value',{attrs:{"value":data.item.calc_talqah_order_discount}})]}},{key:"calc_order_discount",fn:function(ref){
            var data = ref.data;
return [_c('check-financial-value',{attrs:{"value":data.item.calc_order_discount}})]}},{key:"provider_delivery_discount",fn:function(ref){
            var data = ref.data;
return [_c('check-financial-value',{attrs:{"value":data.item.provider_delivery_discount}})]}},{key:"calc_provider_delivery_discount",fn:function(ref){
            var data = ref.data;
return [_c('check-financial-value',{attrs:{"value":data.item.calc_provider_delivery_discount}})]}},{key:"talqah_delivery_discount",fn:function(ref){
            var data = ref.data;
return [_c('check-financial-value',{attrs:{"value":data.item.talqah_delivery_discount}})]}},{key:"calc_talqah_delivery_discount",fn:function(ref){
            var data = ref.data;
return [_c('check-financial-value',{attrs:{"value":data.item.calc_talqah_delivery_discount}})]}},{key:"calc_delivery_discount",fn:function(ref){
            var data = ref.data;
return [_c('check-financial-value',{attrs:{"value":data.item.calc_delivery_discount}})]}},{key:"vendor_talqah_commission_percent",fn:function(ref){
            var data = ref.data;
return [_c('check-financial-value',{attrs:{"value":data.item.vendor_talqah_commission_percent}})]}},{key:"talqah_order_commission",fn:function(ref){
            var data = ref.data;
return [_c('check-financial-value',{attrs:{"value":data.item.talqah_order_commission}})]}},{key:"service_fee",fn:function(ref){
            var data = ref.data;
return [_c('check-financial-value',{attrs:{"value":data.item.service_fee}})]}},{key:"driver_talqah_commission_percent",fn:function(ref){
            var data = ref.data;
return [_c('check-financial-value',{attrs:{"value":data.item.driver_talqah_commission_percent}})]}},{key:"talqah_delivery_commission",fn:function(ref){
            var data = ref.data;
return [_c('check-financial-value',{attrs:{"value":data.item.talqah_delivery_commission}})]}},{key:"provider_profit",fn:function(ref){
            var data = ref.data;
return [_c('check-financial-value',{attrs:{"value":data.item.provider_profit}})]}},{key:"total_price",fn:function(ref){
            var data = ref.data;
return [_vm._v(" "+_vm._s(_vm.formatNumber(data.item.total_price))+" ")]}},{key:"talqah_commission",fn:function(ref){
            var data = ref.data;
return [_vm._v(" "+_vm._s(_vm.formatNumber(data.item.talqah_commission))+" ")]}},{key:"profit",fn:function(ref){
            var data = ref.data;
return [_vm._v(" "+_vm._s(_vm.formatNumber(data.item.profit))+" ")]}},{key:"created_at",fn:function(ref){
            var data = ref.data;
return [_vm._v(" "+_vm._s(_vm.formatDate(data.item.created_at, 'YYYY/MM/DD'))+" ")]}},{key:"action",fn:function(ref){
            var data = ref.data;
return [_c('b-dropdown',{staticClass:"table-option",attrs:{"variant":"background","toggle-class":"text-decoration-none rounded-10 px-2 py-2 ","menu-class":"rounded-8","no-caret":"","dropleft":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('vertical-dots-icon')]},proxy:true}],null,true)},[_c('b-dropdown-item',{attrs:{"to":_vm.goToDetail(data.item.order_id)}},[_c('span',{staticClass:"text-regular-14 text-font-secondary"},[_c('eyeon-icon',{staticClass:"mx-2"}),_vm._v(" "+_vm._s(_vm.$t('common.show_details'))+" ")],1)])],1)]}}],null,false,337690061)}):_vm._e()],1)]),_c('b-col',{staticClass:"px-0",attrs:{"lg":"12","md":"12"}},[(_vm.getTotal)?_c('wameed-pagination',{attrs:{"page":_vm.filterData.page,"total-items":_vm.getTotal.totalItems,"per_page":_vm.filterData.per_page,"menu-title":_vm.$t('common.show_results')},on:{"changePage":_vm.changePage,"changeCurrentPage":_vm.changeCurrentPage},model:{value:(_vm.filterData.page),callback:function ($$v) {_vm.$set(_vm.filterData, "page", $$v)},expression:"filterData.page"}}):_vm._e()],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }